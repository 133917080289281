import * as React from "react";
import { useAuthContext } from "@fitplan/context/lib-es/auth";

interface Props {}

const IFrameAuth: React.FunctionComponent<Props> = ({ ...props }) => {
    const { access_token } = useAuthContext();
    if (typeof window === "undefined") return <div></div>;
    if (window.parent && access_token) {
        window.parent.postMessage(
            { type: "auth", accessToken: access_token },
            "https://fitplanapp.com"
        );
    }
    return <div></div>;
};

export default IFrameAuth;
