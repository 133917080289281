import * as React from "react";
import { useAuthContext } from "@fitplan/context/lib-es/auth";
import IFrameAuth from "../../components/app/IFrameAuth";

interface Props {}

const AuthenticationPage: React.FunctionComponent<Props> = ({ ...props }) => {
    return <IFrameAuth />;
};

export default AuthenticationPage;
